import React, { useRef, useEffect } from 'react';
import classNames from 'classnames';
import uniqueId from 'uniqid';
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';

import rollIn, {
	fadeInAnimation,
	backgroundToBlackAnimation
} from './Animations';

gsap.registerPlugin(ScrollTrigger);

const ns = `reveal`;

const Reveal = ({
	children,
	fadeIn,
	backgroundToBlack,
}) => {
	const rootClassnames = classNames({
		[`${ ns }`]: true,
		[`${ ns }--roll-in`]: !fadeIn && !backgroundToBlack,
		[`${ ns }--fade`]: fadeIn,
		[`${ ns }--background-to-black`]: backgroundToBlack,
	});

	const targetElement = useRef();
	let usedAnimation = rollIn;

	if (fadeIn) {
		usedAnimation = fadeInAnimation;
	} else if (backgroundToBlack) {
		usedAnimation = backgroundToBlackAnimation;
	}

	useEffect(() => {
		const target = targetElement.current;
		const targetId = uniqueId('violux');

		usedAnimation(target, targetId);

		return () => {
			ScrollTrigger.getById(targetId).kill();
		};
	}, [usedAnimation]);

	return (
		<div ref={targetElement} className={rootClassnames}>
			{children}
		</div>
	);
};

export default Reveal;
