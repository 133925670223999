import React from 'react';
import classNames from 'classnames';

import SingleProduct from '../SingleProduct';

const ns = 'product-50-50';

const Product5050 = ({ heading, products }) => {
	const rootClassnames = classNames({
		[`${ ns }`]: true,
	});

	return (
		<section className={`${ rootClassnames }`}>
			<div className={`${ ns }__heading h4`}>{heading}</div>
			{products
				?	(
					<div className={`${ ns }__products`}>
						<SingleProduct
							handle={products.lumaPro.slug}
							title={products.lumaPro.title}
							// subtitle={products.luma.subtitle}
							image={products.lumaPro.imageTilted}
							description={products.lumaPro.description}
							overlayColor={`#F1F2F2`}
							price={products.lumaPro.price}
							// salePrice={products.luma.salePrice}
							salePrice={products.lumaPro.salePrice}
							infoSnippet={products.lumaPro.infoSnippet}
							ctaButton
							infoInOverlay
						/>
						<SingleProduct
							handle={products.lumaProPlus.slug}
							title={products.lumaProPlus.title}
							// subtitle={products.lumaPro.subtitle}
							image={products.lumaProPlus.imageTilted}
							description={products.lumaPro.description}
							overlayColor={`#F1F2F2`}
							price={products.lumaProPlus.price}
							salePrice={products.lumaProPlus.salePrice}
							infoSnippet={products.lumaProPlus.infoSnippet}
							ctaButton
							infoInOverlay
						/>
					</div>
				)
				: <p>No Products found!</p>}
		</section>
	);
};

export default Product5050;
