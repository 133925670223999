import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';

gsap.registerPlugin(ScrollTrigger);

const rollIn = (target, id) => {
	gsap.to(target, {
		// ScrollTrigger settings see link below for all options
		// https://greensock.com/docs/v3/Plugins/ScrollTrigger
		scrollTrigger: {
			// markers: true,
			id,
			start: 'top bottom',
			trigger: target,
			toggleActions: 'play none none reset',
		},
		// setting 'to' / revealed styles here
		y: 0,
		opacity: 1,
		duration: 0.01,
		transition: 'all 1.4s ease',
	});
};

export const fadeInAnimation = (target, id) => {
	gsap.to(target, {
		// ScrollTrigger settings see link below for all options
		// https://greensock.com/docs/v3/Plugins/ScrollTrigger
		scrollTrigger: {
			// markers: true,
			id,
			start: 'top bottom',
			trigger: target,
			toggleActions: 'play none none reset',
		},
		// setting 'to' / revealed styles here
		opacity: 1,
		duration: 0.01,
		transition: 'all 1.4s ease',
	});
};

export const backgroundToBlackAnimation = (target, id) => {
	gsap.set(target, {
		background: 'white',
		transition: 'all 1s ease',
	});

	gsap.to(target, {
		// ScrollTrigger settings see link below for all options
		// https://greensock.com/docs/v3/Plugins/ScrollTrigger
		scrollTrigger: {
			// markers: true,
			id,
			start: 'top+=200 bottom',
			end: 'bottom-=200',
			trigger: target,
			toggleActions: 'play reset play reset',
		},
		// setting 'to' / revealed styles here
		background: 'black',
		duration: 0.01,
		transition: 'all 1s ease',
	});
};

export const pageToBlackAnimation = (target, id) => {
	const body = document.querySelector('body');

	gsap.set(body, {
		background: 'inherit',
		transition: 'all 1s ease',
	});

	gsap.to(body, {
		// ScrollTrigger settings see link below for all options
		// https://greensock.com/docs/v3/Plugins/ScrollTrigger
		scrollTrigger: {
			// markers: true,
			id,
			start: 'top+=200 bottom',
			end: 'bottom-=200',
			trigger: target,
			toggleActions: 'play none play reset',
		},
		// setting 'to' / revealed styles here
		background: 'black',
		duration: 0.01,
		transition: 'all 1s ease',
	});
};

export const dataInView = (target, id) => {
	const body = document.querySelector('body');

	gsap.to(body, {
		// ScrollTrigger settings see link below for all options
		// https://greensock.com/docs/v3/Plugins/ScrollTrigger
		scrollTrigger: {
			// markers: true,
			id,
			start: 'top+=200 bottom',
			end: 'bottom-=200',
			trigger: target,
			toggleActions: 'play none play reset',
			onEnter: () => {
				body.setAttribute('data-inview', id);
			},
			onLeaveBack: () => {
				body.setAttribute('data-inview', '');
			},
		},
	});
};

export default rollIn;
