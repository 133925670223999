import React, { useRef, useEffect } from 'react';
import classNames from 'classnames';
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';

import Image from '../Image/Image';
import Link from '../Link';

gsap.registerPlugin(ScrollTrigger);

const nsBase = 'component';
const ns = `${ nsBase }-hero`;

const Hero = ({
	modifier,
	image,
	video,
	heading,
	description,
	hTag,
	overlay,
	link,
}) => {
	const HTag = hTag;

	const rootClassnames = classNames({
		[`${ ns }`]: true,
		[`${ ns }--${ modifier }`]: modifier,
	});

	const textElement = useRef();
	const imageElement = useRef();

	useEffect(() => {
		const textTarget = textElement ? textElement.current : null;
		const imageTarget = imageElement ? imageElement.current : null;

		if (textTarget) {
			gsap.to(textTarget, {
				// ScrollTrigger settings see link below for all options
				// https://greensock.com/docs/v3/Plugins/ScrollTrigger
				scrollTrigger: {
					// markers: true,
					start: 'top bottom',
					trigger: textTarget,
					toggleActions: 'play none none reset',
				},
				// setting 'to' / revealed styles here
				y: 0,
				opacity: 1,
				duration: 0.01,
				transition: 'all 1.4s ease',
			});
		}

		if (imageTarget) {
			gsap.to(imageTarget, {
				// ScrollTrigger settings see link below for all options
				// https://greensock.com/docs/v3/Plugins/ScrollTrigger
				scrollTrigger: {
					// markers: true,
					start: 'top bottom',
					trigger: imageTarget,
					toggleActions: 'play none none reset',
				},
				// setting 'to' / revealed styles here
				transform: 'none',
				opacity: 1,
				delay: 1.4,
				duration: 0.01,
				transition: 'all 1.4s ease',
			});
		}
	}, []);

	return (
		<div className={`${ rootClassnames }`}>
			{overlay && <div className={`${ ns }__overlay`} />}
			<div className={`${ ns }__text-container`}>
				<div className={`${ ns }__text`} ref={textElement}>
					{heading && (
						<HTag className={`${ ns }__heading`}>
							{ heading }
						</HTag>
					)}
					{description && (
						<p className={`${ ns }__description`}>
							{ description }
						</p>
					)}
					{link && (
						<Link to={link.url} className={`${ ns }__link`}>
							{ link.label }
						</Link>
					)}
				</div>
			</div>

			{(image || video) && (
				<div className={`${ ns }__media-container`} ref={imageElement}>
					{video ? (
						<video data-media-type={'video'} className={`${ ns }__video`} muted playsInline autoPlay loop data-media={'true'}>
							<source src={video} />
							Your browser does not support the video tag.
						</video>
					) : (
						<Image dataMedia {...image} />
					)}
				</div>
			)}
		</div>
	);
};

Hero.defaultProps = {
	hTag: 'h1',
};

export default Hero;
