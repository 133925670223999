import React from 'react';
import classNames from 'classnames';
import uniqueId from 'uniqid';

import Image from '../Image';
import Link from '../Link';
import Reveal from '../Reveal';

const ns = 'alternating-image-text';

const AlternatingImageText = props => {
	const {
		heading,
		paragraph,
		imgOnLeft = false,
		cta,
		infoSnippets,
		modifier,
		textHighlight,
		last,
		hTag,
	} = props;

	const rootClassnames = classNames({
		[`${ ns }`]: true,
		[`${ ns }--${ modifier }`]: modifier,
		imgOnLeft,
		last,
	});

	const HTag = hTag || 'h4';

	return (
		<section className={`${ rootClassnames }`}>
			<div className={'container-fluid'}>
				<Reveal>
					<div className={`${ ns }__container`}>
						<div className={`${ ns }__text-container`}>
							{
								heading
								&& (
									<div className={`${ ns }__heading ${ HTag }`}>
										{
											textHighlight
											&& (
												<span className={`${ ns }__heading--highlight`}>
													{ textHighlight }
													&nbsp;
												</span>
											)
										}
										{ heading }
									</div>
								)
							}
							{
								paragraph && <p className={`${ ns }__paragraph`}>{ paragraph }</p>
							}
							{
								infoSnippets
								&& (
									<div className={`${ ns }__info-snippets`}>
										{infoSnippets.map(snippet => {
											return (
												<div className={`${ ns }__info-snippet`} key={uniqueId('violux')}>
													<h4 className={`${ ns }__snippet-heading`}>{ snippet.heading }</h4>
													<p className={`${ ns }__snippet-paragraph`}>{ snippet.paragraph }</p>
												</div>
											);
										})}
									</div>
								)
							}
							{
								cta && <Link to={cta.link}>{ cta.label }</Link>
							}
						</div>
						<div className={`${ ns }__image-container`}>
							<Image {...props} />
						</div>
					</div>
				</Reveal>
			</div>
		</section>
	);
};

export default AlternatingImageText;
