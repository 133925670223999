import React, { useContext } from 'react';
import classNames from 'classnames';

import StoreContext from '../context/StoreContext';

import SEO from '../components/SEO';
import Hero from '../components/Hero';
import MediaTextFullWidth from '../components/MediaTextFullWidth';
import AlternatingImageText from '../components/AlternatingImageText';
import ThreeTextUp from '../components/ThreeTextUp';
import Slider from '../components/Slider';
import Product5050 from '../components/Product5050/Product-50-50';

const nsBase = 'page';
const ns = `${ nsBase }-home`;

const IndexPage = () => {
	const rootClassnames = classNames({
		[`${ ns }`]: true,
	});

	const {
		products,
	} = useContext(StoreContext);

	return (
		<div className={rootClassnames}>
			<SEO />
			<Hero
				modifier={'home-hero'}
				heading={'Outsmart pathogens.'}
				description={'Confidently clean everyday objects with powerful UV-C light technology.'}
				video={'/assets/videos/Violux_Video_3_compressed.mp4'}
				overlay
			/>
			<ThreeTextUp
				modifier={'home'}
				heading={'A Brand You Can Trust. Effective. Proven. Safe.'}
				blocks={[
					{
						icon: '/assets/images/icons/germ_gray.png',
						heading: 'Kills 99.9%* of germs, \nbacteria and viruses.',
						description: 'Breakthrough UV-C light technology powerfully disinfects by destroying the DNA and RNA of dangerous pathogens.',
					},
					{
						icon: '/assets/images/icons/tested_gray.png',
						heading: 'Laboratory tested \nand certified.',
						description: 'Our devices have been independently tested and certified by a third-party IAC certified medical laboratory.',
					},
					{
						icon: '/assets/images/icons/eco_gray.png',
						heading: 'Chemical-free, ozone-free \nand eco-friendly.',
						description: 'Unlike chemical disinfectants, UV-C light doesn’t leave behind any chemical residue and is safe for use on any item.',
					},
				]}
			/>
			<Product5050
				heading={'Two Powerful Models to Meet Your Cleaning Needs'}
				products={products}
			/>
			<Slider
				modifier={'virus'}
				heading={'Luma kills dangerous pathogens.'}
				description={'<a href="/assets/pdfs/LabTestResults.pdf">Independently tested and certified by an IAC certified medical laboratory.</a>'}
				slides={[
					{
						image: {
							mainImageMobile: '/assets/images/pathogens/Violux_Covid.jpg',
							altText: 'Virus',
						},
						heading: 'Coronavirus (OC43)',
						description: 'Kills 99.99%',
					},
					{
						image: {
							mainImageMobile: '/assets/images/pathogens/Violux_Aureus.jpg',
							altText: 'Virus',
						},
						heading: 'Staphylococcus Aureus (Staph)',
						description: 'Kills 99.9%',
					},
					{
						image: {
							mainImageMobile: '/assets/images/pathogens/Violux_E-Coli.jpg',
							altText: 'Virus',
						},
						heading: 'Escherichia Coli (E. Coli)',
						description: 'Kills 99.9%',
					},
					{
						image: {
							mainImageMobile: '/assets/images/pathogens/Violux_Pneumonia.jpg',
							altText: 'Virus',
						},
						heading: 'Klebsiella Pneumoniae (Bacterial Infections)',
						description: 'Kills 99.99%',
					},
					{
						image: {
							mainImageMobile: '/assets/images/pathogens/Violux_MRSA.jpg',
							altText: 'Virus',
						},
						heading: 'Methicillin-Resistant Staphylococcus Aureus (MRSA)',
						description: 'Kills 99.99%',
					},
					{
						image: {
							mainImageMobile: '/assets/images/pathogens/Violux_Rhinovirus.jpg',
							altText: 'Virus',
						},
						heading: 'Pseudomonas Aeruginosa (Bacterial Infections) ',
						description: 'Kills 99.9%',
					},
					{
						image: {
							mainImageMobile: '/assets/images/pathogens/Violux_Salmonella.jpg',
							altText: 'Virus',
						},
						heading: 'Salmonella Typhimurium (Salmonella)',
						description: 'Kills 99.9%',
					},
					{
						image: {
							mainImageMobile: '/assets/images/pathogens/Violux_Influenza.jpg',
							altText: 'Virus',
						},
						heading: 'Influenza A H1N1 (Common Flu)',
						description: 'Kills 99.9%',
					},
				]}
				sliderSettings={{
					responsive: [
						{
							breakpoint: 992,
							settings: {
								arrows: false,
								dots: true,
								infinite: true,
								slidesToShow: 2,
								slidesToScroll: 2,
							},
						},
						{
							breakpoint: 9999,
							settings: 'unslick',
						},
					],
				}}
			/>
			<AlternatingImageText
				heading={'Destroy everyday germs on your everyday items.'}
				paragraph={'Luma is easy-to-use. With a large capacity chamber and advanced UV-C light cleaning technology, disinfect large objects or multiple objects simultaneously with a single fast clean cycle.'}
				altText={'Everyday items'}
				mainImageMobile={'/assets/images/home/homepage1.jpg'}
				dataMedia
				imgOnLeft
			/>
			<AlternatingImageText
				heading={'More than a smarter way to clean. It’s a smart device.'}
				paragraph={'Luma is Wi-Fi enabled and has smart-sensing and self-diagnostic capabilities. Together with the Violux mobile app, get advanced notifications, smart reminders and be sure that your Luma is always working optimally.'}
				altText={'Luma app'}
				mainImageMobile={'/assets/images/home/homepage2.jpg'}
				dataMedia
				last
			/>
			<Slider
				slides={[
					{
						image: {
							mainImageMobile: '/assets/images/mensjournal.png',
							altText: 'Mens Journal',
						},
						description: '“Living life like The Jetsons is closer than we think.”',
					},
					{
						image: {
							mainImageMobile: '/assets/images/family-handyman-logo.png',
							altText: 'Family Handyman logo',
						},
						description: '“The products are incredibly easy to use — just put your items inside, close the door, hit a button and within three minutes, you’re done.”',
					},
					{
						image: {
							mainImageMobile: '/assets/images/uci-logo.png',
							altText: 'UCI logo',
						},
						description: '“Unlike other methods of disinfection or sanitization, such as bleach and alcohol wipes, Violux products are chemical free and safe for disinfecting food.”',
					},
				]}
				sliderSettings={{
					responsive: [
						{
							breakpoint: 992,
							settings: {
								arrows: false,
								dots: true,
								infinite: true,
								slidesToShow: 1,
								slidesToScroll: 1,
							},
						},
						{
							breakpoint: 9999,
							settings: 'unslick',
						},
					],
				}}
				modifier={'press'}
			/>
			<MediaTextFullWidth
				modifier={'superior-technology'}
				hTag={'h3'}
				heading={'Superior technology. Superior protection.'}
				link={{
					url: '/technology',
					label: 'Learn More',
				}}
				image={{
					mainImageMobile: '/assets/images/home/Superior_Technology.jpg',
					altText: 'Alt Text',
				}}
			/>
		</div>
	);
};

export default IndexPage;
