import React from 'react';
import classNames from 'classnames';
import Image from '../Image';
import Link from '../Link';
import Button from '../Button';
import Reveal from '../Reveal';

const nsBase = 'component';
const ns = `${ nsBase }-media-text-full-width`;

const MediaTextFullWidth = ({
	modifier,
	image,
	video,
	heading,
	description,
	hTag,
	link,
	fadeIn,
	pageToBlack,
	handleAddToCart,
	productSlug,
}) => {
	const HTag = hTag;

	const rootClassnames = classNames({
		[`${ ns }`]: true,
		[`${ ns }--${ modifier }`]: modifier,
	});

	return (
		<div className={`${ rootClassnames }`}>
			<div className={`${ ns }__text-container`}>
				<Reveal
					fadeIn={fadeIn}
					pageToBlack={pageToBlack}
				>
					{heading && (
						<div className={`${ ns }__heading ${ HTag }`}>
							{ heading }
						</div>
					)}
					{description && (
						<p className={`${ ns }__description`}>
							{ description }
						</p>
					)}
					{link && (
						<Link to={link.url} className={`${ ns }__link`}>
							{ link.label }
						</Link>
					)}

					{(modifier === 'pdp-luma') && (productSlug === 'luma-pro-plus') && (
						<div className={`${ ns }__indiegogo`}>
							<Button
								onClick={handleAddToCart}
							>
								Add to cart
							</Button>
						</div>
					)}
				</Reveal>
			</div>

			{(image || video) && (
				<div className={`${ ns }__media-container`}>
					{video ? (
						<video data-media-type={'video'} className={`${ ns }__video`} muted playsInline autoPlay loop data-media={'true'}>
							<source src={video} />
							Your browser does not support the video tag.
						</video>
					) : (
						<Image dataMedia {...image} />
					)}
				</div>
			)}
		</div>
	);
};

MediaTextFullWidth.defaultProps = {
	hTag: 'h1',
};

export default MediaTextFullWidth;
